import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
// import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import Logout from './pages/Logout';
import Settings from './pages/Settings';
import SetorTunai from './pages/SetorTunai'; 
import TarikTunai from './pages/TarikTunai'; 
import Dashboard from './pages/Dashboard';
import Transfer from './pages/Transfer';
import RiwayatTransaksi from './pages/RiwayatTransaksi';
import NotFound from './pages/NotFound';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Router>
      <Navbar />
      <div className="container mt-4">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />\
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/setor-tunai" element={<SetorTunai />} /> 
          <Route path="/tarik-tunai" element={<TarikTunai />} /> 
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/riwayat-transaksi" element={<RiwayatTransaksi />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
