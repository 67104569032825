// src/pages/Profile.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Profile() {
  const userData = {
    nama: 'John Doe',
    email: 'johndoe@example.com',
    nomorTelepon: '08123456789',
    nomorRekening: '1234567890',
    jenisKelamin: 'Laki-laki',  // Informasi tambahan
    tanggalLahir: '1990-01-01', // Informasi tambahan
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="form-container"
      data-aos="fade-up"
    >
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Profil Pengguna</h2>
          <div>
            <p><strong>Nama:</strong> {userData.nama}</p>
            <p><strong>Email:</strong> {userData.email}</p>
            <p><strong>Nomor Telepon:</strong> {userData.nomorTelepon}</p>
            <p><strong>Nomor Rekening:</strong> {userData.nomorRekening}</p>
            <p><strong>Jenis Kelamin:</strong> {userData.jenisKelamin}</p>
            <p><strong>Tanggal Lahir:</strong> {userData.tanggalLahir}</p>
            <Link to="/edit-profile">
              <Button variant="primary" className="w-100">Edit Profil</Button>
            </Link>
          </div>
          <Link to="/dashboard">
            <Button variant="secondary" className="mt-3 w-100">Kembali ke Dashboard</Button>
          </Link>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default Profile;
