import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function NavigationBar() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>BANK ABC</Navbar.Brand> 
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer to="/dashboard">
              <Nav.Link>Dashboard</Nav.Link> 
            </LinkContainer>
            <LinkContainer to="/transfer">
              <Nav.Link>Transfer</Nav.Link> 
            </LinkContainer>
            <LinkContainer to="/profile">
              <Nav.Link>Profil</Nav.Link> 
            </LinkContainer>
            <LinkContainer to="/register">
              <Nav.Link>Daftar</Nav.Link> 
            </LinkContainer>
            <LinkContainer to="/login">
              <Nav.Link>Masuk</Nav.Link> 
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
