import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function EditProfile() {
  const navigate = useNavigate();

  // State untuk menyimpan informasi profil
  const [profileData, setProfileData] = useState({
    nama: 'John Doe',
    email: 'johndoe@example.com',
    nomorTelepon: '08123456789',
    jenisKelamin: 'Laki-laki',
    tanggalLahir: '1990-01-01',
  });

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logika untuk menyimpan perubahan profil
    alert('Profil berhasil diperbarui');
    navigate('/profile'); // Redirect ke halaman profil setelah berhasil disimpan
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="form-container"
      data-aos="fade-up"
    >
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Edit Profil</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="nama" className="mb-3">
              <Form.Label>Nama Lengkap</Form.Label>
              <Form.Control
                type="text"
                name="nama"
                value={profileData.nama}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group id="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={profileData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group id="nomorTelepon" className="mb-3">
              <Form.Label>Nomor Telepon</Form.Label>
              <Form.Control
                type="text"
                name="nomorTelepon"
                value={profileData.nomorTelepon}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group id="jenisKelamin" className="mb-3">
              <Form.Label>Jenis Kelamin</Form.Label>
              <Form.Control
                as="select"
                name="jenisKelamin"
                value={profileData.jenisKelamin}
                onChange={handleChange}
              >
                <option value="Laki-laki">Laki-laki</option>
                <option value="Perempuan">Perempuan</option>
              </Form.Control>
            </Form.Group>
            <Form.Group id="tanggalLahir" className="mb-3">
              <Form.Label>Tanggal Lahir</Form.Label>
              <Form.Control
                type="date"
                name="tanggalLahir"
                value={profileData.tanggalLahir}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button className="w-100" type="submit">
              Simpan Perubahan
            </Button>
          </Form>
          <Button variant="secondary" className="mt-3 w-100" onClick={() => navigate('/profile')}>
            Kembali
          </Button>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default EditProfile;