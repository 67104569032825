// src/pages/RiwayatTransaksi.js
import React, { useState, useEffect } from 'react';
import { Table, Card, Form, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function RiwayatTransaksi() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    jenis: '',
    status: '',
    minJumlah: '',
    maxJumlah: '',
  });

  useEffect(() => {
    const fetchTransactions = () => {
      setTimeout(() => {
        const sampleTransactions = [
          { id: 1, tanggal: '2024-10-01', jenis: 'Transfer', jumlah: 100000, status: 'Berhasil' },
          { id: 2, tanggal: '2024-10-05', jenis: 'Tarik Tunai', jumlah: 50000, status: 'Berhasil' },
          { id: 3, tanggal: '2024-10-07', jenis: 'Setor Tunai', jumlah: 150000, status: 'Gagal' },
          { id: 4, tanggal: '2024-10-10', jenis: 'Transfer', jumlah: 75000, status: 'Gagal' },
        ];
        setTransactions(sampleTransactions);
        setLoading(false);
      }, 1000);
    };

    fetchTransactions();
  }, []);

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const matchesJenis = filter.jenis ? transaction.jenis === filter.jenis : true;
    const matchesStatus = filter.status ? transaction.status === filter.status : true;
    const matchesJumlah =
      (!filter.minJumlah || transaction.jumlah >= parseInt(filter.minJumlah)) &&
      (!filter.maxJumlah || transaction.jumlah <= parseInt(filter.maxJumlah));

    return matchesJenis && matchesStatus && matchesJumlah;
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="form-container"
      data-aos="fade-up"
    >
      <Card className="text-center">
        <Card.Body>
          <h2>Riwayat Transaksi</h2>
          <Form className="mb-3">
            <Form.Group controlId="filterJenis" className="mb-3">
              <Form.Label>Jenis Transaksi</Form.Label>
              <Form.Control as="select" name="jenis" value={filter.jenis} onChange={handleFilterChange}>
                <option value="">Semua</option>
                <option value="Transfer">Transfer</option>
                <option value="Setor Tunai">Setor Tunai</option>
                <option value="Tarik Tunai">Tarik Tunai</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="filterStatus" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" name="status" value={filter.status} onChange={handleFilterChange}>
                <option value="">Semua</option>
                <option value="Berhasil">Berhasil</option>
                <option value="Gagal">Gagal</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="filterMinJumlah" className="mb-3">
              <Form.Label>Jumlah Minimum</Form.Label>
              <Form.Control
                type="number"
                name="minJumlah"
                value={filter.minJumlah}
                onChange={handleFilterChange}
                placeholder="Jumlah minimum"
              />
            </Form.Group>
            <Form.Group controlId="filterMaxJumlah" className="mb-3">
              <Form.Label>Jumlah Maksimum</Form.Label>
              <Form.Control
                type="number"
                name="maxJumlah"
                value={filter.maxJumlah}
                onChange={handleFilterChange}
                placeholder="Jumlah maksimum"
              />
            </Form.Group>
          </Form>
          {loading ? (
            <p>Memuat riwayat transaksi...</p>
          ) : filteredTransactions.length === 0 ? (
            <p>Tidak ada transaksi ditemukan</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>Jenis Transaksi</th>
                  <th>Jumlah</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((transaction, index) => (
                  <tr key={transaction.id}>
                    <td>{index + 1}</td>
                    <td>{transaction.tanggal}</td>
                    <td>{transaction.jenis}</td>
                    <td>Rp {transaction.jumlah.toLocaleString()}</td>
                    <td>{transaction.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Link to="/dashboard">
            <Button variant="secondary" className="mt-3 w-100">Kembali ke Dashboard</Button>
          </Link>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default RiwayatTransaksi;
