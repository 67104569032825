import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="text-center"
      style={{ padding: '100px 20px' }}
    >
      <h1 style={{ fontSize: '4rem', color: '#dc3545' }}>404</h1>
      <h2>Maaf, halaman yang Anda tuju tidak ada</h2>
      <p>Anda akan dialihkan ke halaman utama dalam 5 detik...</p>
    </motion.div>
  );
}

export default NotFound;
