import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Dashboard() {
  // Simulasi data pengguna dan saldo
  const [user] = useState({
    nama: 'John Doe', // Ini bisa diambil dari API atau state global
    saldo: 10000000,  // Misalnya saldo 10,000,000
  });

  // State untuk mengontrol apakah saldo terlihat atau disembunyikan
  const [isSaldoVisible, setIsSaldoVisible] = useState(false);

  // Format untuk menampilkan angka dalam format mata uang
  const formatRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
  };

  // Fungsi untuk toggle (lihat/tutup saldo)
  const toggleSaldo = () => {
    setIsSaldoVisible(!isSaldoVisible);
  };

  return (
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.5 }}
      data-aos="zoom-in"
    >
      {/* Menampilkan Salam kepada pengguna */}
      <h2 className="mb-4">Selamat datang, {user.nama}!</h2>

      {/* Kartu Saldo */}
      <Card className="mb-4">
        <Card.Body className="d-flex justify-content-between align-items-center">
          <div>
            <h5>Saldo Anda</h5>
            {/* Tampilkan saldo jika isSaldoVisible true, sensor jika false */}
            <h3>{isSaldoVisible ? formatRupiah(user.saldo) : 'Rp ******'}</h3>

          </div>
          <Button variant="link" onClick={toggleSaldo} style={{ fontSize: '1.5rem' }}>
            {/* Ikon mata untuk toggle saldo */}
            <FontAwesomeIcon icon={isSaldoVisible ? faEyeSlash : faEye} />
          </Button>
        </Card.Body>
      </Card>

      <Row className="mt-4">
        {/* Fitur Setor Tunai */}
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Setor Tunai</Card.Title>
              <Card.Text>Tambah saldo ke akun Anda dengan mudah.</Card.Text>
              <Link to="/setor-tunai">
                <Button variant="success">Setor Tunai</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Fitur Tarik Tunai */}
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Tarik Tunai</Card.Title>
              <Card.Text>Tarik saldo dari akun Anda dengan aman.</Card.Text>
              <Link to="/tarik-tunai">
                <Button variant="danger">Tarik Tunai</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Fitur Transfer Dana */}
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Transfer Dana</Card.Title>
              <Card.Text>Transfer uang ke rekening lain dengan aman.</Card.Text>
              <Link to="/transfer">
                <Button variant="success">Transfer</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Fitur Riwayat Transaksi */}
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Riwayat Transaksi</Card.Title>
              <Card.Text>Lihat riwayat transaksi Anda.</Card.Text>
              <Link to="/riwayat-transaksi">
                <Button variant="info">Riwayat</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Fitur Edit Profile */}
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Edit Profile</Card.Title>
              <Card.Text>Mengedit Informasi Pribadi Anda.</Card.Text>
              <Link to="/edit-profile">
                <Button variant="success">Edit Profile</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        {/* Fitur Logout User */}
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Logout</Card.Title>
              <Card.Text>Keluar dari Aplikasi.</Card.Text>
              <Link to="/logout">
                <Button variant="danger">Logout</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </motion.div>
  );
}

export default Dashboard;
