import React, { useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './Global.css';

function Register() {
  const [nama, setNama] = useState('');
  const [email, setEmail] = useState('');
  const [nomorTelepon, setNomorTelepon] = useState('');
  const [pin, setPin] = useState('');
  const [konfirmasiPin, setKonfirmasiPin] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validasi form sederhana
    if (pin !== konfirmasiPin) {
      setError('PIN dan Konfirmasi PIN tidak cocok');
      return;
    }

    // Simulasi API call untuk registrasi
    setTimeout(() => {
      setSuccess('Registrasi berhasil! Anda akan diarahkan ke halaman login.');
      setError('');

      // Redirect ke halaman login setelah 3 detik
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }, 1000);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="form-container"
      data-aos="fade-up"
    >
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Daftar Akun</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="nama" className="mb-3">
              <Form.Label>Nama Lengkap</Form.Label>
              <Form.Control
                type="text"
                required
                value={nama}
                onChange={(e) => setNama(e.target.value)}
              />
            </Form.Group>
            <Form.Group id="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group id="nomorTelepon" className="mb-3">
              <Form.Label>Nomor Telepon</Form.Label>
              <Form.Control
                type="text"
                required
                value={nomorTelepon}
                onChange={(e) => setNomorTelepon(e.target.value)}
              />
            </Form.Group>
            <Form.Group id="pin" className="mb-3">
              <Form.Label>Buat PIN</Form.Label>
              <Form.Control
                type="password"
                required
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </Form.Group>
            <Form.Group id="konfirmasiPin" className="mb-3">
              <Form.Label>Konfirmasi PIN</Form.Label>
              <Form.Control
                type="password"
                required
                value={konfirmasiPin}
                onChange={(e) => setKonfirmasiPin(e.target.value)}
              />
            </Form.Group>
            <Button className="w-100" type="submit">
              Daftar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default Register;
