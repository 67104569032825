import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Home.css';

function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="home"
    >
      <Container className="text-center mt-5">
        <h1 className="display-4">Selamat Datang di Bank ABC</h1>
        <p className="lead">Mengelola keuangan Anda dengan mudah dan aman.</p>
        <Link to="/register">
          <Button variant="primary" size="lg" className="mt-3">
            Mulai Sekarang
          </Button>
        </Link>
      </Container>

      <Container className="mt-5">
        <Row className="text-center">
          <Col md={4}>
            <i className="fas fa-lock fa-3x mb-3"></i>
            <h4>Keamanan Terjamin</h4>
            <p>
              Dengan sistem keamanan berlapis, transaksi Anda akan selalu aman dan terlindungi.
            </p>
          </Col>
          <Col md={4}>
            <i className="fas fa-chart-line fa-3x mb-3"></i>
            <h4>Transaksi Mudah</h4>
            <p>
              Kelola saldo, transfer, dan cek transaksi kapan saja, di mana saja.
            </p>
          </Col>
          <Col md={4}>
            <i className="fas fa-users fa-3x mb-3"></i>
            <h4>Layanan Terbaik</h4>
            <p>
              Nikmati layanan perbankan digital terbaik dengan kemudahan penggunaan.
            </p>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}

export default Home;
