import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import { motion } from 'framer-motion';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Mengatur timeout untuk redirect ke halaman login setelah 3 detik
    const timer = setTimeout(() => {
      navigate('/login');
    }, 3000); // 3000 ms = 3 detik

    // Cleanup timeout jika komponen di-unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="form-container"
    >
      <Card className="text-center">
        <Card.Body>
          <h2>Logout Berhasil</h2>
          <p>Anda akan dialihkan ke halaman login dalam beberapa detik...</p>
          <Spinner animation="border" role="status" className="mt-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default Logout;
