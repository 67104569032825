import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function Settings() {
  const [newPin, setNewPin] = useState('');
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();
    // Logic untuk menyimpan perubahan
    alert('Pengaturan disimpan');
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="form-container"
      data-aos="fade-up"
    >
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Pengaturan Akun</h2>
          <Form onSubmit={handleSave}>
            <Form.Group id="pin" className="mb-3">
              <Form.Label>Ganti PIN</Form.Label>
              <Form.Control
                type="text"  // Menggunakan text agar kita bisa kontrol validasi lebih baik
                value={newPin}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {  // Memastikan hanya angka
                    setNewPin(value);
                    }
                }}
                placeholder="Masukkan PIN lama"
                maxLength="8"  // Membatasi panjang input maksimal 8 karakter
                inputMode="numeric"  // Untuk memberikan keyboard angka di perangkat mobile
              /> <br/>
              <Form.Control
                type="text"  // Menggunakan text agar kita bisa kontrol validasi lebih baik
                value={newPin}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {  // Memastikan hanya angka
                    setNewPin(value);
                    }
                }}
                placeholder="Masukkan PIN baru"
                maxLength="8"  // Membatasi panjang input maksimal 8 karakter
                inputMode="numeric"  // Untuk memberikan keyboard angka di perangkat mobile
              /> <br/>
              <Form.Control
                type="text"  // Menggunakan text agar kita bisa kontrol validasi lebih baik
                value={newPin}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {  // Memastikan hanya angka
                    setNewPin(value);
                    }
                }}
                placeholder="Verifikasi PIN baru"
                maxLength="8"  // Membatasi panjang input maksimal 8 karakter
                inputMode="numeric"  // Untuk memberikan keyboard angka di perangkat mobile
              />
            </Form.Group>
            <Form.Group id="2fa" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Aktifkan Autentikasi Dua Faktor (2FA)"
                checked={twoFactorEnabled}
                onChange={() => setTwoFactorEnabled(!twoFactorEnabled)}
              />
            </Form.Group>
            <Button className="w-100" type="submit">Simpan Pengaturan</Button>
          </Form>
          <Link to="/dashboard">
            <Button variant="secondary" className="mt-3 w-100">Kembali ke Dashboard</Button>
          </Link>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default Settings;
