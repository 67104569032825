import React, { useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './Global.css';

function SetorTunai() {
  const [jumlah, setJumlah] = useState('');
  const [pin, setPin] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleSetorTunai = (e) => {
    e.preventDefault();
    // Logika setor tunai (misalnya dengan call API)
    if (jumlah && pin) {
      setSuccess(`Setor tunai sebesar Rp ${parseInt(jumlah).toLocaleString()} berhasil!`);
      setError('');
      setJumlah('');
      setPin('');
    } else {
      setError('Semua field wajib diisi');
      setSuccess('');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="form-container"
      data-aos="fade-up"
    >
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Setor Tunai</h2>
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSetorTunai}>
            <Form.Group id="jumlah" className="mb-3">
              <Form.Label>Jumlah Setor</Form.Label>
              <Form.Control
                type="number"
                placeholder="Masukkan jumlah"
                value={jumlah}
                onChange={(e) => setJumlah(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group id="pin" className="mb-3">
              <Form.Label>PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="Masukkan PIN"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                required
              />
            </Form.Group>
            <Button className="w-100" type="submit">
              Setor Tunai
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default SetorTunai;